export const FILTER_BUTTON = 'filter_button'
export const GO_BACK_BUTTON = 'back_button'
export const COURSE_TITLE = 'course_title'
export const EXPAND_BUTTON = 'expand_button'
export const SECTION_SELECT = 'section_select'
export const SUBJECT_SELECT_BUTTON = 'subject_select_button'
export const COURSE_SEARCH_BOX = 'course_search_box'
export const SEARCH_QUERY = 'search_query'
export const SNACKBAR_BUTTON = 'snackbar_button'
export const SELECTED_COURSES_BUTTON = 'selected_courses_button'
export const DAY_FILTER = 'day_search_filter'
export const GENED_FILTER = 'gened_search_filter'
export const GRADING_FILTER = 'grading_search_filter'
export const PERIOD_RANGE_FILTER = 'period_range_filter'
export const SHOPPING_CART_BUTTON = 'shopping_cart_button'
export const SHOPPING_CART_REMOVE_COURSE = 'shopping_cart_remove_course'
export const OPEN_SHOPPING_CART_BUTTON = 'open_shopping_cart_button'
export const SUBJECT_SELECT_BUTTON_WITH_SECTION = 'subject_select_button_with_section'
export const CR11_BUTTON = 'cr11_button'
export const EXAM_TAB_BUTTON = 'exam_tab_button'
export const CLASS_TAB_BUTTON = 'class_tab_button'
export const EXPORT_PNG_BUTTON = 'export_png_button'
export const HIDE_COURSE = 'hide_course'
export const DELETE_COURSE = 'delete_course'
export const SECTION_CHANGE = 'section_change'
export const NAVBAR_TIMETABLE = 'navbar_timetable'
export const NAVBAR_SEARCH_COURSE = 'navbar_search_course'
export const NAVBAR_ABOUT = 'navbar_about'
export const GETREG_LOGO = 'getreg_logo'
export const STUDY_PROGRAM_DROPDOWN = 'study_program_dropdown'
export const REPORT_PROBLEM = 'report_problem'
export const LOGIN_BUTTON = 'login_button'
export const LOGOUT_BUTTON = 'logout_button'
export const SELECTED_COURSE_TITLE = 'selected_course_title'
export const COURSE_DIALOG_CHEVRON = 'course_dialog_chevron'
export const COURSE_DIALOG_TITLE = 'course_dialog_title'
export const TERM_DROPDOWN = 'term_drop_down'
export const COURSE_TITLE_SCHEDULE_CARD = 'course_title_schedule_card'
export const COLOR_PICKER_BUTTON = 'color_picker_button'
export const COLOR_BUTTON = 'color_button'
export const INTERACTIVE_SCHEDULE = 'interactive_schedule'
