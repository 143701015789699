/**
 * Future are temporary types.
 *
 * `Semester` enum will be typed from backend soon.
 */

export enum Semester {
  First = '1',
  Second = '2',
  Third = '3',
}
